var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useNavigate } from 'react-router';
import { SEARCH_PAGE_INIT, } from '@lean-body/src/domain';
import { routes } from '@lean-body/src/route';
import { PageLoading, SearchResults, SelectOption, selectOptions } from '@lean-body/components/organisms';
import { SearchKeywordBox } from '@lean-body/components/organisms/search/search_keyword_box';
import { SearchConditionsButton, SearchSortDialog, SearchConditionDialog } from '@lean-body/components/molecules';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { useQuery } from '@lean-body/src/util/custom_hooks';
import { LocalStorage, SEARCH_HISTORIES_MAX_NUM } from '@lean-body/src/infra';
import { ToggleTabs } from '../molecules/toggle_tabs';
var useState = React.useState, useEffect = React.useEffect;
export var PageStatus;
(function (PageStatus) {
    PageStatus["Default"] = "default";
    PageStatus["SearchDone"] = "searchDone";
})(PageStatus || (PageStatus = {}));
export var SearchResultSectionTabs = {
    all: 'すべて',
    lessons: 'レッスン',
    seriesPrograms: 'シリーズ',
    playlists: 'プレイリスト',
};
export var SearchResultPage = function (_a) {
    var _b, _c, _d;
    var apiClient = _a.apiClient, tracker = _a.tracker;
    var query = useQuery();
    var navigate = useNavigate();
    var localStorage = new LocalStorage();
    var currentSort = query.get('sort_by') || SelectOption.Favorite;
    var currentStyles = ((_b = query.get('style_id')) === null || _b === void 0 ? void 0 : _b.split(',').map(Number)) || [];
    var currentDifficulties = ((_c = query.get('difficulty_id')) === null || _c === void 0 ? void 0 : _c.split(',').map(Number)) || [];
    var currentDurations = ((_d = query.get('duration_id')) === null || _d === void 0 ? void 0 : _d.split(',').map(Number)) || [];
    var searchHistories = localStorage.loadSearchHistories || [];
    var _e = useState(), lessonStyles = _e[0], setLessonStyles = _e[1];
    var _f = useState(), durations = _f[0], setDurations = _f[1];
    var _g = useState(), difficulties = _g[0], setDifficulties = _g[1];
    var _h = useState(currentStyles), styleIDs = _h[0], setStyleIDs = _h[1];
    var _j = useState(currentDifficulties), difficultyIDs = _j[0], setDifficultyIDs = _j[1];
    var _k = useState(currentDurations), durationIDs = _k[0], setDurationIDs = _k[1];
    var _l = useState(query.get('q') || ''), keyword = _l[0], setKeyword = _l[1];
    var _m = useState(), searchKeywords = _m[0], setSearchKeywords = _m[1];
    var _o = useState(), lessonsNextButtonDisplayable = _o[0], setLessonsNextButtonDisplayable = _o[1];
    var _p = useState(), playlistsNextButtonDisplayable = _p[0], setPlaylistsNextButtonDisplayable = _p[1];
    var _q = useState(), seriesProgramsNextButtonDisplayable = _q[0], setSeriesProgramsNextButtonDisplayable = _q[1];
    var _r = useState(), lessons = _r[0], setLessons = _r[1];
    var _s = useState(), lessonsCount = _s[0], setLessonsCount = _s[1];
    var _t = useState(), playlists = _t[0], setPlaylists = _t[1];
    var _u = useState(), playlistsCount = _u[0], setPlaylistsCount = _u[1];
    var _v = useState(), seriesPrograms = _v[0], setSeriesPrograms = _v[1];
    var _w = useState(), seriesProgramsCount = _w[0], setSeriesProgramsCount = _w[1];
    var _x = useState(false), showSortDialog = _x[0], setShowSortDialog = _x[1];
    var _y = useState(false), showConditionDialog = _y[0], setShowConditionDialog = _y[1];
    var _z = useState(currentSort), sortBy = _z[0], setSortBy = _z[1];
    var _0 = useState(PageStatus.Default), pageStatus = _0[0], setPageStatus = _0[1];
    var _1 = useState(true), isLoading = _1[0], setIsLoading = _1[1];
    var _2 = React.useState(SearchResultSectionTabs.all), tab = _2[0], setTab = _2[1];
    var _3 = React.useState(SearchResultSectionTabs), tabs = _3[0], setTabs = _3[1];
    var _4 = useState(SEARCH_PAGE_INIT), page = _4[0], setPage = _4[1];
    var _5 = useState([]), searchResults = _5[0], setSearchResults = _5[1];
    var submitDisabled = (keyword === null || keyword === void 0 ? void 0 : keyword.length) <= 1;
    var displaySortLabel = selectOptions.find(function (v) {
        return v.value === currentSort;
    }).name;
    var saveSearchHistory = function (str) {
        if (str === '')
            return;
        // 検索ワードが既に履歴に存在する場合、先頭に移動する
        if (searchHistories.includes(str)) {
            searchHistories = searchHistories.filter(function (his) { return his !== str; });
        }
        searchHistories.unshift(str);
        if (searchHistories.length > SEARCH_HISTORIES_MAX_NUM)
            searchHistories.pop();
        localStorage.saveSearchHistories(searchHistories);
    };
    useEffect(function () {
        Promise.all([
            apiClient.fetchStyles(),
            apiClient.fetchDurationsForSearch(),
            apiClient.getKeywords(),
            apiClient.fetchDifficulties(),
        ]).then(function (_a) {
            var styles = _a[0], durations = _a[1], keywords = _a[2], difficulties = _a[3];
            setLessonStyles(styles);
            setDurations(durations);
            setSearchKeywords(keywords.keywords);
            setDifficulties(difficulties);
            search();
        });
    }, []);
    var buildSearchParams = function (q) {
        if (q === void 0) { q = ''; }
        var params = { sortBy: sortBy };
        var category = query.get('category');
        if (q !== '') {
            params.q = q;
        }
        else if (keyword !== null) {
            params.q = keyword;
        }
        if (category !== null)
            params.category = category;
        if (styleIDs.length)
            params.styleId = styleIDs.join(',');
        if (difficultyIDs.length)
            params.difficultyId = difficultyIDs.join(',');
        if (durationIDs.length)
            params.durationId = durationIDs.join(',');
        return params;
    };
    var search = function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (nextPage, q) {
            var pageCount, searchParams, searchResult;
            if (nextPage === void 0) { nextPage = false; }
            if (q === void 0) { q = ''; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setNextButtonDisplayable(false);
                        pageCount = page;
                        if (nextPage) {
                            pageCount++;
                            setPage(pageCount);
                            setIsLoading(true);
                        }
                        else {
                            setPageStatus(PageStatus.Default);
                        }
                        searchParams = buildSearchParams(q);
                        return [4 /*yield*/, apiClient.search(searchParams, pageCount)];
                    case 1:
                        searchResult = _a.sent();
                        setLessonsAndPlaylistsAndPrograms(nextPage, searchResult);
                        saveSearchHistory(q);
                        changeNextButtonDisplayable(pageCount, searchResult);
                        setSearchResultTabs(searchResult);
                        // キーワードが変更れた時だけタブをリセットする
                        if (q !== '') {
                            setTab(SearchResultSectionTabs.all);
                            setPage(SEARCH_PAGE_INIT);
                        }
                        setIsLoading(false);
                        if (!nextPage)
                            window.scrollTo(0, 0);
                        setPageStatus(PageStatus.SearchDone);
                        return [2 /*return*/];
                }
            });
        });
    };
    var changeNextButtonDisplayable = function (pageCount, searchResult) {
        var _a, _b, _c;
        if ((_a = searchResult.lessons) === null || _a === void 0 ? void 0 : _a.length) {
            setLessonsNextButtonDisplayable(searchResult.perPage * pageCount < searchResult.lessonsCount);
        }
        if ((_b = searchResult.programs) === null || _b === void 0 ? void 0 : _b.length) {
            setSeriesProgramsNextButtonDisplayable(searchResult.perPage * pageCount < searchResult.programsCount);
        }
        if ((_c = searchResult.playlists) === null || _c === void 0 ? void 0 : _c.length) {
            setPlaylistsNextButtonDisplayable(searchResult.perPage * pageCount < searchResult.playlistsCount);
        }
    };
    var setNextButtonDisplayable = function (nextButtonDisplayable) {
        if (lessons)
            setLessonsNextButtonDisplayable(nextButtonDisplayable);
        if (seriesPrograms)
            setSeriesProgramsNextButtonDisplayable(nextButtonDisplayable);
        if (playlists)
            setPlaylistsNextButtonDisplayable(nextButtonDisplayable);
    };
    var setLessonsAndPlaylistsAndPrograms = function (nextPage, searchResult) {
        if (nextPage) {
            setLessons(lessons.concat(searchResult.lessons));
            seriesPrograms.length
                ? setSeriesPrograms(seriesPrograms.concat(searchResult.programs))
                : setSeriesPrograms(searchResult.programs);
            playlists.length ? setPlaylists(playlists.concat(searchResult.playlists)) : setPlaylists(searchResult.playlists);
            setSearchResults(searchResults.concat(searchResult));
        }
        else {
            setLessons(searchResult.lessons);
            setSeriesPrograms(searchResult.programs);
            setPlaylists(searchResult.playlists);
            setLessonsCount(searchResult.lessonsCount);
            setSeriesProgramsCount(searchResult.programsCount);
            setPlaylistsCount(searchResult.playlistsCount);
            setSearchResults([searchResult]);
        }
    };
    var setSearchResultTabs = function (searchResult) {
        // 検索結果が1件も取得できなかったタブを非表示にするためにディープコピーする
        var resultTabs = JSON.parse(JSON.stringify(SearchResultSectionTabs));
        if (!searchResult.lessonsCount && (searchResult.programsCount || searchResult.playlistsCount))
            delete resultTabs.lessons;
        if (!searchResult.programsCount)
            delete resultTabs.seriesPrograms;
        if (!searchResult.playlistsCount)
            delete resultTabs.playlists;
        setTabs(resultTabs);
    };
    var onClickResultLesson = function (lesson) {
        tracker.trackClickSearchResultLesson(lesson.id);
    };
    var onClickResultProgram = function (program) {
        tracker.trackClickSearchResultProgram(program.id);
    };
    var onClickResultPlaylist = function (playlist) {
        tracker.trackClickSearchResultProgram(playlist.id);
    };
    var onClickBack = function () {
        navigate(routes.SEARCH.compile());
    };
    var searchQuery = function (keywordValue) {
        var searchQuery = '';
        var queryList = [];
        if (keywordValue)
            queryList.push("q=".concat(keywordValue));
        if (sortBy)
            queryList.push("sort_by=".concat(sortBy));
        if (styleIDs.length)
            queryList.push("style_id=".concat(styleIDs));
        if (difficultyIDs.length)
            queryList.push("difficulty_id=".concat(difficultyIDs));
        if (durationIDs.length)
            queryList.push("duration_id=".concat(durationIDs));
        if (queryList.length) {
            queryList.forEach(function (query, i) {
                var prefix = i === 0 ? '?' : '&';
                searchQuery = searchQuery + prefix + query;
            });
        }
        return searchQuery;
    };
    var onClickKeyword = function (keyword) {
        setKeyword(keyword);
        navigate("".concat(routes.SEARCH_RESULT.compile()).concat(searchQuery(keyword)));
        search(false, keyword);
    };
    var onChangeKeyword = function (keyword) {
        setKeyword(keyword);
    };
    var submitKeyword = function (e) {
        navigate("".concat(routes.SEARCH_RESULT.compile()).concat(searchQuery(keyword)));
        search(false, keyword);
        tracker.trackSubmitSearch(keyword);
        e.preventDefault();
    };
    var clearKeyword = function () {
        setKeyword('');
    };
    var onClickConditionDialog = function () {
        setShowConditionDialog(true);
    };
    var submitConditionDialog = function () {
        setShowConditionDialog(false);
        navigate("".concat(routes.SEARCH_RESULT.compile()).concat(searchQuery(keyword)));
        search(false, keyword);
    };
    var cancelConditionDialog = function () {
        setShowConditionDialog(false);
        setStyleIDs(currentStyles);
        setDifficultyIDs(currentDifficulties);
        setDurationIDs(currentDurations);
    };
    var onClickSortDialog = function () {
        setShowSortDialog(true);
    };
    var submitSortDialog = function () {
        setShowSortDialog(false);
        navigate("".concat(routes.SEARCH_RESULT.compile()).concat(searchQuery(keyword)));
        search(false, keyword);
    };
    var cancelSortDialog = function () {
        setShowSortDialog(false);
        setSortBy(currentSort);
    };
    var enteredConditions = function () {
        return currentStyles.length > 0 || currentDifficulties.length > 0 || currentDurations.length > 0;
    };
    if (isLoading || !tabs) {
        React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container, (showConditionDialog || showSortDialog) && styles.openDialog) },
        React.createElement("div", { className: css(styles.searchContainer) },
            React.createElement(SearchKeywordBox, { keywordValue: keyword, searchKeywords: searchKeywords, searchHistories: searchHistories, submitDisabled: submitDisabled, onChangeKeyword: onChangeKeyword, onSelectedKeyword: onClickKeyword, clear: clearKeyword, submit: submitKeyword }),
            React.createElement("div", { className: css(styles.searchConditionsContainer) },
                React.createElement(SearchConditionsButton, { label: "\u7D5E\u308A\u8FBC\u307F", enteredForButtonColor: enteredConditions(), onClick: onClickConditionDialog }),
                React.createElement(SearchConditionsButton, { label: displaySortLabel, enteredForButtonColor: false, onClick: onClickSortDialog })),
            React.createElement(ToggleTabs, { setTab: setTab, currentTab: tab, tabs: tabs, classObject: styles.tabWrapper })),
        React.createElement(SearchResults, { searchResults: searchResults, sortBy: sortBy, page: page, lessons: lessons, seriesPrograms: seriesPrograms, playlists: playlists, lessonsCount: lessonsCount, seriesProgramsCount: seriesProgramsCount, playlistsCount: playlistsCount, onClickLesson: onClickResultLesson, onClickProgram: onClickResultProgram, onClickPlaylist: onClickResultPlaylist, onClickBack: onClickBack, selectedTab: tab, search: search, lessonsNextButtonDisplayable: lessonsNextButtonDisplayable, playlistsNextButtonDisplayable: playlistsNextButtonDisplayable, seriesProgramsNextButtonDisplayable: seriesProgramsNextButtonDisplayable, pageStatus: pageStatus, isLoading: isLoading, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram }),
        showConditionDialog && (React.createElement(SearchConditionDialog, { lessonStyles: lessonStyles, durations: durations, difficulties: difficulties, currentLessonStyles: styleIDs, currentDifficulties: difficultyIDs, currentDurations: durationIDs, setCurrentLessonStyles: setStyleIDs, setCurrentDifficulties: setDifficultyIDs, setCurrentDurations: setDurationIDs, onClickSubmit: submitConditionDialog, onClickCancel: cancelConditionDialog })),
        showSortDialog && (React.createElement(SearchSortDialog, { currentValue: sortBy, setCurrentValue: setSortBy, onClickSubmit: submitSortDialog, onClickCancel: cancelSortDialog }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            margin: '0 auto',
            maxWidth: 1184,
            width: '100%'
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 40,
            padding: 0,
        },
        _a),
    openDialog: {
        overflewY: 'hidden',
    },
    searchContainer: (_b = {
            textAlign: 'center',
            padding: '0 15px'
        },
        _b[MediaBreakPointUp.MD] = {
            padding: '0 48px',
        },
        _b),
    searchConditionsContainer: {
        display: 'flex',
        maxWidth: 800,
        margin: '14px auto',
    },
    tabWrapper: {
        margin: '12px 0 0',
        minHeight: 32,
    },
    selectedTabName: {
        color: Colors.PrimaryDark,
        fontWeight: 'bold',
    },
    indicator: {
        backgroundColor: Colors.PrimaryDark,
    },
});
