var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c, _d, _e;
import * as React from 'react';
import LazyLoad from 'react-lazyload';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { css, StyleSheet } from 'aphrodite';
import { routes } from '@lean-body/src/route';
import { LessonRanking, SearchSuggestionSection } from '@lean-body/components/organisms';
import { UnderbarTabs } from '@lean-body/components/molecules/underbar_tabs';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { LocalStorage, SEARCH_HISTORIES_MAX_NUM } from '@lean-body/src/infra';
import { isAndroidApp, useQuery, useXlMediaQuery } from '@lean-body/src/util';
import { SearchKeywordBox } from '@lean-body/components/organisms/search/search_keyword_box';
import SearchInstructor from '@lean-body/components/organisms/search/search_instructor';
import SearchTitle from '@lean-body/components/organisms/search/search_title';
import { SearchDuration } from '@lean-body/components/organisms/search/search_duration';
import { SearchDifficulty } from '@lean-body/components/organisms/search/search_difficulty';
import { SearchProgram } from '../organisms/search/search_program';
import { MoreLink } from '../atoms/more_link';
import { BannerSection } from '../organisms/banner_section';
import { AnnouncementBanner } from '../molecules';
import { ProgramComponentCarousel } from '../organisms/program_component_carousel';
import { MerchandiseCarousel } from '../organisms/merchandise_carousel';
import { LessonComponentCarousel } from '../organisms/lesson_component_carousel';
export var SEARCH_SELECTED_TAB = 'selectedTab';
export var SEARCH_SELECTED_OPTION = 'selectedOption';
export var SearchTabs = {
    recommend: 'おすすめ',
    categorySearch: 'カテゴリから探す',
};
export var SearchTabKeys = {
    recommend: 'recommend',
    categorySearch: 'categorySearch',
};
var CAROUSEL_COUNT = 10;
var SERIES_PROGRAMS_FIRST_PAGE = 0;
var MERCHANDISE_FIRST_PAGE = 1;
var RANKING_LIMIT_COUNT = 10;
var RANKING_ITEM_SIZE = 320;
var RANKING_ITEM_SIZE_OVER_XL = 660;
export var LIST_ITEM_MARGIN_RIGHT = 10;
var SWIPER_AUTOPLAY_DELAY = 4000;
var RECOMMEND_SIDE_MARGIN_SP = 20;
var RECOMMEND_SIDE_MARGIN_MD = 24;
var RECOMMEND_SIDE_MARGIN_XL = 32;
export var MainSearchPage = function (_a) {
    var apiClient = _a.apiClient, accountService = _a.accountService, config = _a.config, tracker = _a.tracker;
    var isXL = useXlMediaQuery();
    var query = useQuery();
    var navigate = useNavigate();
    var localStorage = new LocalStorage();
    var _b = useState(), payment = _b[0], setPayment = _b[1];
    var _c = useState([]), lessonSections = _c[0], setLessonSections = _c[1];
    var _d = useState([]), seriesProgramsSection = _d[0], setSeriesProgramsSection = _d[1];
    var _e = useState([]), liveLessonProgramSection = _e[0], setLiveLessonProgramSection = _e[1];
    var _f = useState(localStorage.loadSearchHistories || []), searchHistories = _f[0], setSearchHistories = _f[1];
    var _g = useState(), keyword = _g[0], setKeyword = _g[1];
    var _h = useState([]), searchKeywords = _h[0], setSearchKeywords = _h[1];
    var _j = useState([]), banners = _j[0], setBanners = _j[1];
    var _k = useState([]), contentStyles = _k[0], setContentStyles = _k[1];
    var _l = useState([]), partTags = _l[0], setPartTags = _l[1];
    var _m = useState([]), trainers = _m[0], setTrainers = _m[1];
    var _o = useState([]), durations = _o[0], setDurations = _o[1];
    var _p = useState([]), difficulties = _p[0], setDifficulties = _p[1];
    var _q = useState([]), rankings = _q[0], setRankings = _q[1];
    var _r = useState([]), merchandises = _r[0], setMerchandises = _r[1];
    var _s = useState((keyword === null || keyword === void 0 ? void 0 : keyword.length) < 1), submitDisabled = _s[0], setSubmitDisabled = _s[1];
    var _t = useState(SearchTabs[query.get(SEARCH_SELECTED_TAB)] || SearchTabs.recommend), tab = _t[0], setTab = _t[1];
    var tabs = Object.assign(SearchTabs);
    useEffect(function () {
        apiClient.getKeywords().then(function (v) { return setSearchKeywords(v.keywords); });
    }, []);
    useEffect(function () {
        if (tab === SearchTabs.recommend) {
            navigate("".concat(routes.SEARCH.compile(), "?").concat(SEARCH_SELECTED_TAB, "=").concat(SearchTabKeys.recommend), { replace: true });
            if (!lessonSections.length)
                apiClient.fetchLessonSection().then(function (v) { return setLessonSections(v); });
            if (!banners.length)
                apiClient.getHomeBanners().then(function (v) { return setBanners(v); });
            if (!rankings.length)
                apiClient.fetchDailyLessonRanking(RANKING_LIMIT_COUNT).then(function (v) { return setRankings(v); });
            if (!merchandises.length) {
                apiClient.getMerchandises(MERCHANDISE_FIRST_PAGE).then(function (v) { return setMerchandises(merchandises.concat(v)); });
            }
            if (!seriesProgramsSection.length) {
                apiClient
                    .fetchProgramList(SERIES_PROGRAMS_FIRST_PAGE)
                    .then(function (programs) { return setSeriesProgramsSection(programs.slice(0, CAROUSEL_COUNT)); });
            }
            if (!payment)
                accountService.fetchMe().then(function (me) { return setPayment(me.payment); });
            if (!liveLessonProgramSection.length) {
                apiClient.fetchLiveLessonProgramSection().then(function (v) { return setLiveLessonProgramSection(v); });
            }
        }
        else if (tab === SearchTabs.categorySearch) {
            navigate("".concat(routes.SEARCH.compile(), "?").concat(SEARCH_SELECTED_TAB, "=").concat(SearchTabKeys.categorySearch), { replace: true });
            if (!contentStyles.length)
                apiClient.fetchStyles().then(function (v) { return setContentStyles(v); });
            if (!partTags.length)
                apiClient.fetchPartTags().then(function (v) { return setPartTags(v); });
            if (!trainers.length)
                apiClient.fetchTrainers().then(function (v) { return setTrainers(v); });
            if (!durations.length)
                apiClient.fetchDurationsForSearch().then(function (v) { return setDurations(v); });
            if (!difficulties.length)
                apiClient.fetchDifficulties().then(function (v) { return setDifficulties(v); });
        }
    }, [tab]);
    useEffect(function () {
        setSubmitDisabled((keyword === null || keyword === void 0 ? void 0 : keyword.length) < 1);
    }, [keyword]);
    var search = function (q) {
        // 検索履歴の保存処理
        saveSearchHistory();
        navigate("".concat(routes.SEARCH_RESULT.compile(), "?q=").concat(q));
    };
    var saveSearchHistory = function () {
        // 検索ワードが既に履歴に存在する場合、先頭に移動するので一旦削除する
        if (searchHistories.includes(keyword)) {
            setSearchHistories(searchHistories.filter(function (his) { return his !== keyword; }));
        }
        // 先頭に検索ワードを追加する
        setSearchHistories(__spreadArray([keyword], searchHistories, true));
        // 履歴件数が最大を超えた場合に最後の検索ワードを削除する
        if (searchHistories.length > SEARCH_HISTORIES_MAX_NUM) {
            setSearchHistories(searchHistories.filter(function (_, i) { return i < searchHistories.length - 1; }));
        }
        localStorage.saveSearchHistories(searchHistories);
    };
    var onChangeKeyword = function (value) {
        setKeyword(value);
    };
    var onClickKeyword = function (q) {
        setKeyword(q);
        search(q);
    };
    var onSubmit = function () {
        search(keyword);
        tracker.trackSubmitSearch(keyword);
    };
    var onCancel = function () {
        setKeyword('');
    };
    var onClickDuration = function (durationID) {
        navigate({ pathname: routes.SEARCH_RESULT.compile(), search: "duration_id=".concat(durationID) });
    };
    var onClickDifficulty = function (difficultyID) {
        navigate({ pathname: routes.SEARCH_RESULT.compile(), search: "difficulty_id=".concat(difficultyID) });
    };
    var onClickStyleCard = function (styleID) {
        tracker.trackClickSearchStyleCard(styleID);
        navigate({ pathname: routes.SEARCH_RESULT.compile(), search: "style_id=".concat(styleID) });
    };
    var onClickPartCard = function (partTagId) {
        tracker.trackClickSearchPartCard(partTagId);
        var curTag = partTags.find(function (v) { return v.id === partTagId; });
        setKeyword(curTag.name);
        search(curTag.name);
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.centerContentContainer) },
            React.createElement(SearchKeywordBox, { keywordValue: keyword, searchKeywords: searchKeywords, searchHistories: searchHistories, submitDisabled: submitDisabled, onChangeKeyword: onChangeKeyword, onSelectedKeyword: onClickKeyword, clear: onCancel, submit: onSubmit }),
            React.createElement(UnderbarTabs, { setTab: setTab, currentTab: tab, tabs: tabs, classObject: styles.tabWrapper })),
        tab === SearchTabs.recommend ? (
        ////////////////////////////
        // おすすめ タブが選択された場合
        ////////////////////////////
        React.createElement(React.Fragment, null,
            !!banners.length && (React.createElement(BannerSection, { tracker: tracker, banners: banners, autoplayDelay: SWIPER_AUTOPLAY_DELAY })),
            !!rankings.length && (React.createElement(LazyLoad, null,
                React.createElement("div", { className: css(styles.sectionHeader) },
                    React.createElement("div", { className: css(styles.sectionTitle) }, "\u30C7\u30A4\u30EA\u30FC\u30E9\u30F3\u30AD\u30F3\u30B0"),
                    React.createElement(MoreLink, { to: routes.RANKING.compile() })),
                React.createElement("div", { onClick: function () { return tracker.trackHomeClick('daily_ranking'); } },
                    React.createElement(LessonRanking, { rankings: rankings, classObject: styles.sectionContentContainer, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, itemSize: isXL ? RANKING_ITEM_SIZE_OVER_XL : RANKING_ITEM_SIZE, isBig: isXL })))),
            !!lessonSections.length && (React.createElement(React.Fragment, null, lessonSections.map(function (v, i) { return (React.createElement(LazyLoad, { key: i },
                React.createElement("div", { className: css(styles.sectionHeader) },
                    React.createElement("div", { className: css(styles.sectionTitle) }, v.name)),
                React.createElement("div", { onClick: function () { return tracker.trackHomeClick('lesson_section'); } },
                    React.createElement(LessonComponentCarousel, { classObject: styles.sectionContentContainer, lessons: v.lessons, addFavoriteLesson: apiClient.addFavoriteLesson, unFavoriteLesson: apiClient.unFavoriteLesson, itemMarginRight: LIST_ITEM_MARGIN_RIGHT, isMin: !isXL })))); }))),
            !!seriesProgramsSection.length && (React.createElement(LazyLoad, null,
                React.createElement("div", { className: css(styles.sectionHeader) },
                    React.createElement("div", { className: css(styles.sectionTitle) }, "\u65B0\u7740\u30B7\u30EA\u30FC\u30BA")),
                React.createElement("div", { onClick: function () { return tracker.trackHomeClick('series_program'); } },
                    React.createElement(ProgramComponentCarousel, { programs: seriesProgramsSection, classObject: styles.sectionContentContainer, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, itemMarginRight: LIST_ITEM_MARGIN_RIGHT, isMin: !isXL })))),
            !!liveLessonProgramSection.length && (React.createElement(LazyLoad, null,
                React.createElement("div", { className: css(styles.sectionHeader) },
                    React.createElement("div", { className: css(styles.sectionTitle) }, "LIVE\u914D\u4FE1\u30A2\u30FC\u30AB\u30A4\u30D6")),
                React.createElement("div", null,
                    React.createElement(ProgramComponentCarousel, { programs: liveLessonProgramSection, classObject: styles.sectionContentContainer, addFavoriteProgram: apiClient.addFavoriteProgram, unFavoriteProgram: apiClient.unFavoriteProgram, itemMarginRight: LIST_ITEM_MARGIN_RIGHT, isMin: !isXL })))),
            payment && payment.isMegalosUser && (React.createElement(LazyLoad, null,
                React.createElement("div", { className: css(styles.sectionHeader) },
                    React.createElement("div", { className: css(styles.sectionTitle) }, "LEAN BODY \u00D7 MEGALOS")),
                React.createElement("div", { className: css(styles.sectionContentContainer, styles.megalosContent), onClick: function () { return tracker.trackHomeClick('megalos'); } },
                    React.createElement(AnnouncementBanner, { pcBannerImgPath: "assets/images/banner/megalos_live_calendar_banner_pc.png", spBannerImgPath: "assets/images/banner/megalos_live_calendar_banner.png", linkPath: routes.MEGALOS_LIVE_CALENDAR.compile(), dataTest: "megalosLive" }),
                    React.createElement(AnnouncementBanner, { pcBannerImgPath: "assets/images/banner/megalos_lesson_pc.png", spBannerImgPath: "assets/images/banner/megalos_lesson_sp.png", linkPath: routes.INSTRUCTOR.compile({ id: '33' }) })))),
            !isAndroidApp() && !!merchandises.length && (React.createElement(LazyLoad, null,
                React.createElement("div", { className: css(styles.sectionHeader) },
                    React.createElement("div", { className: css(styles.sectionTitle) }, "\u30D4\u30C3\u30AF\u30A2\u30C3\u30D7\u5546\u54C1"),
                    React.createElement(MoreLink, { to: routes.MERCHANDISE.compile() })),
                React.createElement(MerchandiseCarousel, { merchandises: merchandises, classObject: styles.sectionContentContainer, itemMarginRight: LIST_ITEM_MARGIN_RIGHT }))))) : (tab === SearchTabs.categorySearch && (
        ////////////////////////////
        // カテゴリから探す タブが選択された場合
        ////////////////////////////
        React.createElement("div", { className: css(styles.categorySearchContainer) },
            React.createElement("div", { className: css(styles.categorySearchBox) },
                React.createElement(SearchTitle, null, "\u30B8\u30E3\u30F3\u30EB"),
                React.createElement(SearchSuggestionSection, { items: contentStyles, onClickItem: onClickStyleCard, config: config }),
                React.createElement(SearchProgram, null),
                React.createElement(SearchDuration, { onClickDuration: onClickDuration, durations: durations }),
                React.createElement(SearchDifficulty, { onClickDifficulty: onClickDifficulty, difficulties: difficulties }),
                React.createElement(SearchTitle, null, "\u6C17\u306B\u306A\u308B\u90E8\u4F4D"),
                React.createElement(SearchSuggestionSection, { items: partTags, onClickItem: onClickPartCard, config: config }),
                trainers && React.createElement(SearchInstructor, { trainers: trainers })))))));
};
var styles = StyleSheet.create({
    container: (_a = {
            width: '100%',
            margin: '0 auto',
            paddingBottom: 30
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 40,
        },
        _a),
    centerContentContainer: (_b = {
            textAlign: 'center',
            width: '100%',
            padding: '0 16px'
        },
        _b[MediaBreakPointUp.MD] = {
            margin: 'auto',
            padding: 0,
            maxWidth: 600,
        },
        _b[MediaBreakPointUp.XL] = {
            maxWidth: 1024,
        },
        _b),
    tabWrapper: {
        margin: '16px 0 0',
        minHeight: '32px',
    },
    sectionHeader: (_c = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 62,
            padding: "24px ".concat(RECOMMEND_SIDE_MARGIN_SP, "px 12px")
        },
        _c[MediaBreakPointUp.MD] = {
            padding: "24px ".concat(RECOMMEND_SIDE_MARGIN_MD, "px 12px"),
        },
        _c[MediaBreakPointUp.XL] = {
            padding: "24px ".concat(RECOMMEND_SIDE_MARGIN_XL, "px 12px"),
        },
        _c),
    sectionTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    sectionContentContainer: (_d = {
            padding: "0 ".concat(RECOMMEND_SIDE_MARGIN_SP, "px")
        },
        _d[MediaBreakPointUp.MD] = {
            padding: "0 ".concat(RECOMMEND_SIDE_MARGIN_MD, "px"),
        },
        _d[MediaBreakPointUp.XL] = {
            padding: "0 ".concat(RECOMMEND_SIDE_MARGIN_XL, "px"),
        },
        _d),
    megalosContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    categorySearchContainer: (_e = {
            width: '100%',
            padding: '0 20px'
        },
        _e[MediaBreakPointUp.MD] = {
            margin: 'auto',
            padding: 0,
            maxWidth: 600,
        },
        _e[MediaBreakPointUp.XL] = {
            maxWidth: 1024,
        },
        _e),
    categorySearchBox: {
        margin: '0 auto',
        maxWidth: 800,
    },
});
