var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { css, StyleSheet } from 'aphrodite';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { bind } from 'bind-decorator';
import * as React from 'react';
import { Colors } from '@lean-body/components/styles';
import * as msg from '@lean-body/src/messages';
import { CCForm } from '@lean-body/components/molecules/cc_form';
import { Button, ButtonType } from '../atoms';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { SettingItemKeys } from './setting_menu';
export var CCChangeSubsection = observer(function (_a) {
    var classObject = _a.classObject, accountService = _a.accountService, apiClient = _a.apiClient, alertPresenter = _a.alertPresenter, config = _a.config, me = _a.me;
    var vm = new CCChangeSubsectionVM(accountService, apiClient, alertPresenter, config, me);
    var invoiceFailed = vm.me.invoiceFailed;
    var canChangeCreditCard = !!vm.me.canChangeCreditCard;
    var navigate = useNavigate();
    var canceleUnsubscribeSchedule = function () {
        navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
    };
    return (React.createElement("div", { className: css(styles.container, classObject), id: 'change_cc' }, canChangeCreditCard ? (React.createElement(React.Fragment, null,
        invoiceFailed && (React.createElement("div", { className: css(styles.messageInvoiceFailed) },
            "\u5229\u7528\u6599\u306E\u652F\u6255\u3044\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002",
            React.createElement("br", null),
            "\u304A\u624B\u6570\u3067\u3059\u304C\u3001\u6709\u52B9\u306A\u304A\u652F\u6255\u3044\u60C5\u5831\u3078\u306E\u5909\u66F4\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002",
            React.createElement("br", null),
            "1\u30F6\u6708\u4EE5\u5185\u306B\u6709\u52B9\u306A\u304A\u652F\u6255\u3044\u60C5\u5831\u3078\u306E\u5909\u66F4\u304C\u306A\u3044\u5834\u5408\u306F\u9000\u4F1A\u6271\u3044\u3068\u3055\u305B\u3066\u3044\u305F\u3060\u304D\u307E\u3059\u3002",
            React.createElement("br", null))),
        React.createElement("div", { className: css(styles.currentCC) },
            React.createElement("div", { className: css(styles.currentCCLabel) }, "\u73FE\u5728\u306E\u30AF\u30EC\u30B8\u30C3\u30C8\u30AB\u30FC\u30C9"),
            React.createElement("div", null, "**** **** **** ".concat(vm.curCCLastFour))),
        React.createElement(CCForm, { alertPresenter: vm.alertPresenter, submitLabel: "\u5909\u66F4", config: vm.config, onSubmit: vm.onSubmit, disableCCForm: vm.disableCCForm }))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.messageForDeleted) }, "\u9000\u4F1A\u4E2D\u306E\u305F\u3081\u304A\u652F\u6255\u3044\u65B9\u6CD5\u306E\u5909\u66F4\u3092\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u305B\u3093\u3002"),
        React.createElement(Button, { buttonType: ButtonType.primary, onClick: canceleUnsubscribeSchedule, fullWidth: true }, "\u9000\u4F1A\u3092\u53D6\u308A\u6D88\u3059")))));
});
var CCChangeSubsectionStatus;
(function (CCChangeSubsectionStatus) {
    CCChangeSubsectionStatus[CCChangeSubsectionStatus["prepareing"] = 0] = "prepareing";
    CCChangeSubsectionStatus[CCChangeSubsectionStatus["ready"] = 1] = "ready";
    CCChangeSubsectionStatus[CCChangeSubsectionStatus["failedToPrepare"] = 2] = "failedToPrepare";
    CCChangeSubsectionStatus[CCChangeSubsectionStatus["submitting"] = 3] = "submitting";
    CCChangeSubsectionStatus[CCChangeSubsectionStatus["finished"] = 4] = "finished";
    CCChangeSubsectionStatus[CCChangeSubsectionStatus["failed"] = 5] = "failed";
})(CCChangeSubsectionStatus || (CCChangeSubsectionStatus = {}));
var Status = CCChangeSubsectionStatus;
var CCChangeSubsectionVM = /** @class */ (function () {
    function CCChangeSubsectionVM(accountService, apiClient, alertPresenter, config, me) {
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "disableCCForm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: CCChangeSubsectionStatus.prepareing
        });
        Object.defineProperty(this, "curCCLastFour", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "me", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            status: observable,
            curCCLastFour: observable,
            me: observable,
            disableCCForm: observable,
        });
        this.accountService = accountService;
        this.alertPresenter = alertPresenter;
        this.apiClient = apiClient;
        this.config = config;
        this.me = me;
        this.onMeUpdated(me);
        this.setStatus(Status.ready);
    }
    Object.defineProperty(CCChangeSubsectionVM.prototype, "setStatus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (status) {
            this.status = status;
            switch (status) {
                case Status.prepareing: // fallthrough
                case Status.finished:
                    this.disableCCForm = false;
                    break;
                case Status.submitting:
                    this.disableCCForm = true;
                    break;
                default:
                    this.disableCCForm = false;
                    break;
            }
        }
    });
    Object.defineProperty(CCChangeSubsectionVM.prototype, "onSubmit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (stripeSourceToken) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    this.setStatus(Status.submitting);
                    return [2 /*return*/, this.apiClient
                            .changePaymentSource({ sourceToken: stripeSourceToken })
                            .then(function () {
                            _this.accountService.fetchMe({ nocache: true }).then(function (me) {
                                _this.me = me;
                                _this.onMeUpdated(me);
                                _this.setStatus(Status.finished);
                                _this.alertPresenter.showMessage(msg.CC_CHANGE_SUCCEEDED, 'cc_change_result');
                            });
                        })
                            .catch(function (err) {
                            _this.setStatus(Status.failed);
                            _this.alertPresenter.showError(err, msg.CC_CHANGE_FAILED, 'cc_change_result');
                        })];
                });
            });
        }
    });
    Object.defineProperty(CCChangeSubsectionVM.prototype, "onMeUpdated", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (me) {
            if (!me) {
                this.curCCLastFour = null;
                return;
            }
            if (me.payment) {
                this.curCCLastFour = me.payment.cardLastFour;
            }
            else {
                this.curCCLastFour = null;
            }
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Promise)
    ], CCChangeSubsectionVM.prototype, "onSubmit", null);
    return CCChangeSubsectionVM;
}());
export { CCChangeSubsectionVM };
var styles = StyleSheet.create({
    container: {},
    currentCC: {
        marginBottom: 8,
        textAlign: 'center',
    },
    currentCCLabel: {
        marginBottom: 4,
        fontSize: 14,
        color: Colors.GrayDarkest,
    },
    messageForDeleted: {
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 24,
    },
    messageInvoiceFailed: {
        color: Colors.Red,
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 24,
    },
});
