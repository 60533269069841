import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { CampaignCodeForm } from '@lean-body/components/molecules';
import { Colors } from '../styles';
import { ArrowDirection, ArrowIcon } from '../atoms/icons/arrow_icon';
import { NoticeSection } from '../atoms/notice_section';
import { LocalStorage } from '@lean-body/src/infra';
/**
 * キャンペーンコード入力セクション
 */
export var CampaignCodeFormSection = function (props) {
    var onSubmit = props.onSubmit, classObject = props.classObject;
    var _a = React.useState(''), value = _a[0], setValue = _a[1];
    var _b = React.useState(false), isShowForm = _b[0], setIsShowForm = _b[1];
    var _c = React.useState(null), campaign = _c[0], setCampaign = _c[1];
    var localStorage = new LocalStorage();
    var onChangeKeyword = function (e) {
        setValue(e.target.value.trim());
    };
    var handleOnSubmit = function () {
        onSubmit(value).then(function (v) {
            localStorage.saveCampaingCode(value);
            setCampaign(v);
        });
    };
    if (campaign) {
        return (React.createElement(NoticeSection, null,
            React.createElement("span", { className: css(styles.campaignName) }, campaign.name),
            "\u304C\u9069\u7528\u3055\u308C\u307E\u3057\u305F"));
    }
    return (React.createElement("div", { className: css(classObject) },
        React.createElement("div", { className: css(styles.container, isShowForm && styles.containerOpened) },
            React.createElement("div", { className: css(styles.switch), onClick: function () { return setIsShowForm(!isShowForm); }, id: 'campaign-code', "data-test": "campaignCodeDisplayable" },
                "\u30AF\u30FC\u30DD\u30F3\u30B3\u30FC\u30C9\u3092\u304A\u6301\u3061\u306E\u65B9",
                React.createElement(ArrowIcon, { classObject: styles.arrow, direction: isShowForm ? ArrowDirection.top : ArrowDirection.bottom })),
            isShowForm && (React.createElement(CampaignCodeForm, { value: value, onChangeKeyword: onChangeKeyword, submitDisabled: value.length === 0, onSubmit: handleOnSubmit, classObject: styles.form })))));
};
var styles = StyleSheet.create({
    container: {
        maxHeight: 22,
        overflow: 'hidden',
        transition: 'max-height 0.2s',
    },
    containerOpened: {
        maxHeight: 100,
    },
    campaignName: {
        color: Colors.Accent,
    },
    switch: {
        fontSize: 14,
        fontWeight: 'bold',
        color: Colors.PrimaryDark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    arrow: {
        marginLeft: 10,
        width: 14,
        height: 8,
    },
    form: {
        marginTop: 20,
    },
});
